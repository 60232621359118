/* global $cookies */
import Vue from 'vue';

import VTooltip from 'v-tooltip';
import LoadScript from 'vue-plugin-load-script';
import components from './components';
import modal from './components/modal-dialog';
import cookieNotice from './components/cookie-notice';
import countdownProvider from './components/countdown-provider';
import countdown from './components/countdown';
import reveal from './components/reveal';
import hasState from './components/has-state';
import streamCountdown from './components/stream-countdown';
import emailSubscriptionProvider from './components/email-subscription-provider';
import anchor from './components/anchor';
import anchorReference from './components/anchor-reference';
import TwitterTweet from './components/twitter/TwitterTweet';
import EditorContent from './components/editor-content';
import DateRangeSelector from './components/date-range-selector';
import NotSoSimpleCalendar from './components/simple-calendar';
import LoadScriptWrap from '@common/components/LoadScript.vue';
import TrinityAudito from './components/trinity-audio.vue';
import eventCountdown from './components/event-countdown.vue';
import ReadMore from './components/ReadMore.vue';
import Collapsible from './components/Collapsible.vue';
import Podcast from './components/podcasts/Podcast.vue';
import youtubeVideo from './components/youtube-video.vue';
import YoutubeSwiper from './components/YoutubeSwiper.vue';
import EcZagrebSwiper from './components/EcZagrebSwiper.vue';

import './livecasts';

import cookies from 'vue-cookies';
import {
  eventsSwiper,
  jdAcademySwiper,
  ourPresidentSwiper,
  relatedPrograms,
  caocIssuesSwiper,
  swiper,
  videosSwiper,
  testimonialSwiper,
  testimonialDurbanSwiper,
  thisWeekJewishHistorySwiper,
  ihraNewPageSwiper,
  ihraMisconceptionsPageSwiper,
  nextGenSwiper,
  AnniversaryCarousel,
  wordsWJCElevateSwiper,
  bosniaDelegationCarousel,
  newsHeaderDisplay,
  eventsFellowCarousel,
  nextGenIncSwiper,
  shareSwiper,
  ab80Swiper,
  ecZagrebImageSwiper,
  fightHateInstagramSwiper,
  swiperWithoutLoop,
  eventsSlowSwiper,
  swiperWithLoopShowNextImage,
  swiperWithAutoSlidesPerView
} from './swiper';
import AOS from 'aos';
import 'aos/dist/aos.css';

// Bootstrap all public admin goodies
require('../admin/public/bootstrap');

export default function () {
  components();

  Vue.use(LoadScript);
  Vue.use(VTooltip);
  Vue.use(cookies);

  Vue.component('anchor', anchor);
  Vue.component('anchor-reference', anchorReference);
  Vue.component('cookie-notice', cookieNotice);
  Vue.component('countdown', countdown);
  Vue.component('countdown-provider', countdownProvider);
  Vue.component('date-range-selector', DateRangeSelector);
  Vue.component('not-so-simple-calendar', NotSoSimpleCalendar);
  Vue.component('editor-content', EditorContent);
  Vue.component('email-subscription-provider', emailSubscriptionProvider);
  Vue.component('has-state', hasState);
  Vue.component('modal', modal);
  Vue.component('notified-news', () =>
    import('./components/notified-news.vue')
  );
  Vue.component('photo-gallery', () => import('./components/photo-gallery'));
  Vue.component('carousel', () => import('./components/carousel'));
  Vue.component('gallery-kristallnacht', () =>
    import('./components/gallery-kristallnacht')
  );
  Vue.component('simple-gallery', () => import('./components/simple-gallery'));
  Vue.component('reveal', reveal);
  Vue.component('stream-countdown', streamCountdown);
  Vue.component('event-countdown', eventCountdown);
  Vue.component('swiper', () => import('./components/swiper.vue'));
  Vue.component('twitter-tweet', TwitterTweet);
  Vue.component('load-script', LoadScriptWrap);
  Vue.component('cards-component', () =>
    import('./components/israel-101/Cards.vue')
  );
  Vue.component('trinity-audio', TrinityAudito);
  Vue.component('sidebar-navigation', () =>
    import('./components/sidebar-navigation.vue')
  );
  Vue.component('ihra-swiper', () =>
    import('./components/ihra/ihra-swiper.vue')
  );

  Vue.component('just-a-dropdown', () =>
    import('./components/generic-dropdown/Dropdown.vue')
  );
  Vue.component('collapsible', Collapsible);
  Vue.component('read-more', ReadMore);
  Vue.component('combat-antisemitism-map', () =>
    import('./components/combat-antisemitism/CombatAntisemitismMap.vue')
  );

  Vue.component('airtable', () => import('./components/airtable/Airtable.vue'));
  Vue.component('imgix-image', () =>
    import('../admin/asset-manager/components/ImgixImage.vue')
  );
  Vue.component('podcast', Podcast);
  Vue.component('youtube-video', youtubeVideo);
  Vue.component('youtube-swiper', YoutubeSwiper);
  Vue.component('mini-swiper', EcZagrebSwiper);

  window.BUS = new Vue({});

  new Vue({
    el: '#vueApp',
    data: {
      isPopupVisible: false,
      state: {}
    },
    mounted() {
      eventsSwiper();
      jdAcademySwiper();
      ourPresidentSwiper();
      relatedPrograms();
      caocIssuesSwiper();
      swiper();
      videosSwiper();
      testimonialSwiper();
      testimonialDurbanSwiper();
      thisWeekJewishHistorySwiper();
      ihraNewPageSwiper();
      ihraMisconceptionsPageSwiper();
      nextGenSwiper();
      AnniversaryCarousel();
      wordsWJCElevateSwiper();
      bosniaDelegationCarousel();
      newsHeaderDisplay();
      eventsFellowCarousel();
      nextGenIncSwiper();
      shareSwiper();
      ab80Swiper();
      ecZagrebImageSwiper();
      fightHateInstagramSwiper();
      swiperWithoutLoop();
      swiperWithLoopShowNextImage();
      eventsSlowSwiper();
      swiperWithAutoSlidesPerView();
      AOS.init();
    },
    created() {
      $cookies.set('user_session_cookie', true, 0);
      // this.countdownRunning(this.countdown.start, this.countdown.end);
    },
    methods: {
      setState(key, value) {
        this.state = { ...this.state, [key]: value };
      },

      countdownRunning(start, end) {
        // Get todays date and time
        let now = new Date().getTime();

        // Find the distance between now an the count down date
        let distance = start - now;
        let passTime = end - now;

        if ((distance < 0 && passTime < 0) || (distance > 0 && passTime > 0)) {
          this.countdown.isRunning = false;
        } else if (distance < 0 && passTime > 0) {
          this.countdown.isRunning = true;
        }
      },
      updateCountdownRunning(updatedRunning) {
        this.countdown.isRunning = updatedRunning;
      },
      updatePopupVisible(updatedPopupVisible) {
        this.isPopupVisible = updatedPopupVisible;
      }
    }
  });
}

import Swiper from 'swiper';

export function eventsSwiper() {
  new Swiper('.js-events-swiper-container', {
    loop: true,
    simulateTouch: false,
    spaceBetween: 20,
    slidesPerView: 1,
    centeredSlides: true,
    speed: 500,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.js-events-swiper-next',
      prevEl: '.js-events-swiper-prev'
    },
    breakpoints: {
      576: {
        slidesPerView: 2,
        centeredSlides: false
      },
      992: {
        slidesPerView: 3
      },
      1200: {
        spaceBetween: 30,
        slidesPerView: 3
      }
    }
  });
}

export function eventsSlowSwiper() {
  new Swiper('.js-slow-events-swiper-container', {
    loop: true,
    simulateTouch: false,
    spaceBetween: 20,
    slidesPerView: 1,
    centeredSlides: true,
    speed: 2000,
    autoplay: {
      delay: 10000,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.js-events-swiper-next',
      prevEl: '.js-events-swiper-prev'
    },
    breakpoints: {
      576: {
        slidesPerView: 2,
        centeredSlides: false
      },
      992: {
        slidesPerView: 3
      },
      1200: {
        spaceBetween: 30,
        slidesPerView: 3
      }
    }
  });
}

export function jdAcademySwiper() {
  new Swiper('.js-jdAcademy-swiper-container', {
    watchOverflow: true,
    simulateTouch: true,
    slidesPerView: 1,
    spaceBetween: 20,
    speed: 1000,
    loop: true,
    autoplay: {
      delay: 7000,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.js-swiper-next',
      prevEl: '.js-swiper-prev',
      disabledClass: 'disabled_swiper_button_ec_zagreb'
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      type: 'bullets'
    },
    breakpoints: {
      576: {
        slidesPerView: 2
      },
      992: {
        slidesPerView: 3
      }
    }
  });
}

export function ourPresidentSwiper() {
  new Swiper('.js-swiper-our-president', {
    watchOverflow: true,
    simulateTouch: false,
    slidesPerView: 1,
    spaceBetween: 20,
    speed: 500,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.js-swiper-next',
      prevEl: '.js-swiper-prev'
    },
    breakpoints: {
      576: {
        slidesPerView: 2
      },
      992: {
        slidesPerView: 3
      },
      1200: {
        spaceBetween: 30,
        slidesPerView: 4
      }
    }
  });
}

export function caocIssuesSwiper() {
  new Swiper('.js-swiper-issues', {
    watchOverflow: true,
    simulateTouch: false,
    slidesPerView: 1,
    spaceBetween: 20,
    speed: 500,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.js-swiper-issues-next',
      prevEl: '.js-swiper-issues-prev'
    },
    breakpoints: {
      576: {
        slidesPerView: 2
      },
      992: {
        slidesPerView: 3
      },
      1200: {
        spaceBetween: 30,
        slidesPerView: 4
      }
    }
  });
}

export function swiper() {
  document
    .querySelectorAll('.js-swiper-container:not([manual-init])')
    .forEach((node) => {
      let slidesPerView = node.dataset.swiperSlidesPerView || 1;
      let slidesPerViewMd = node.dataset.swiperSlidesPerViewMd || 2;
      let slidesPerViewLg = node.dataset.swiperSlidesPerViewLg || 3;
      let slidesPerViewXl = node.dataset.swiperSlidesPerViewXl || 3;

      new Swiper(node, {
        watchOverflow: true,
        simulateTouch: false,
        slidesPerView,
        spaceBetween: 20,
        speed: 500,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        navigation: {
          nextEl: '.js-swiper-next',
          prevEl: '.js-swiper-prev'
        },
        breakpoints: {
          576: {
            slidesPerView: slidesPerViewMd
          },
          992: {
            slidesPerView: slidesPerViewLg
          },
          1200: {
            spaceBetween: 30,
            slidesPerView: slidesPerViewXl
          }
        }
      });
    });
}

export function videosSwiper() {
  new Swiper('.js-videos-swiper-container', {
    watchOverflow: true,
    simulateTouch: false,
    slidesPerView: 1,
    spaceBetween: 20,
    speed: 500,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.js-video-swiper-next',
      prevEl: '.js-video-swiper-prev'
    },
    breakpoints: {
      576: {
        slidesPerView: 2
      },
      992: {
        slidesPerView: 3
      },
      1200: {
        spaceBetween: 30,
        slidesPerView: 4
      }
    }
  });
}

export function testimonialSwiper() {
  new Swiper('.js-testimonial-swiper-container', {
    watchOverflow: true,
    simulateTouch: false,
    slidesPerView: 1,
    spaceBetween: 20,
    speed: 500,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },

    navigation: {
      nextEl: '.js-swiper-next',
      prevEl: '.js-swiper-prev'
    },
    breakpoints: {
      576: {
        slidesPerView: 2
      },
      992: {
        slidesPerView: 3
      },
      1200: {
        spaceBetween: 30,
        slidesPerView: 3
      }
    }
  });
}

export function testimonialDurbanSwiper() {
  new Swiper('.js-testimonial-durban-swiper-container', {
    watchOverflow: true,
    simulateTouch: false,
    slidesPerView: 1,
    spaceBetween: 20,
    speed: 1000,
    loop: true,
    autoplay: {
      delay: 7000,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.js-swiper-next',
      prevEl: '.js-swiper-prev'
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      type: 'bullets'
    }
  });
}

export function thisWeekJewishHistorySwiper() {
  new Swiper('.this-week-jewish-history-swiper-container', {
    watchOverflow: true,
    simulateTouch: false,
    slidesPerView: 'auto',
    spaceBetween: 24,
    speed: 9000,
    loop: true,
    loopedSlides: 6,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
      pauseOnMouseEnter: true
    }
  });
}

export function ihraMisconceptionsPageSwiper() {
  new Swiper('.ihra-misconceptions-swiper-container', {
    watchOverflow: true,
    simulateTouch: true,
    allowTouchMove: true,
    slidesPerView: 'auto',
    spaceBetween: 24,
    speed: 6000,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
      pauseOnMouseEnter: true
    },
    allowTouchMove: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  });
}

export function ihraNewPageSwiper() {
  new Swiper('.ihra-new-page-swiper-container', {
    watchOverflow: true,
    simulateTouch: true,
    allowTouchMove: true,
    slidesPerView: 'auto',
    spaceBetween: 24,
    speed: 6000,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
      pauseOnMouseEnter: true
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  });
}

export function nextGenSwiper() {
  new Swiper('.next-gen-swiper', {
    watchOverflow: true,
    simulateTouch: false,
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    speed: 5000,
    autoplay: {
      delay: 1000,
      disableOnInteraction: false
    }
  });
}

export function AnniversaryCarousel() {
  new Swiper('.anniversary-carousel', {
    watchOverflow: true,
    simulateTouch: false,
    slidesPerView: 1,
    loop: true,
    spaceBetween: 20,
    speed: 500,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.js-swiper-next',
      prevEl: '.js-swiper-prev'
    }
  });
}

export function wordsWJCElevateSwiper() {
  new Swiper('.js-testimonial-words-elevate-swiper-container', {
    watchOverflow: true,
    simulateTouch: false,
    slidesPerView: 1,
    spaceBetween: 20,
    speed: 1000,
    autoplay: {
      delay: 10000,
      disableOnInteraction: false
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  });
}

export function bosniaDelegationCarousel() {
  new Swiper('.bosnia-delegation-carousel', {
    watchOverflow: true,
    simulateTouch: false,
    slidesPerView: 1,
    loop: true,
    spaceBetween: 20,
    speed: 500,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  });
}

export function eventsFellowCarousel() {
  new Swiper('.fellowship-events-carousel', {
    watchOverflow: true,
    simulateTouch: false,
    slidesPerView: 1,
    loop: true,
    spaceBetween: 30,
    speed: 500,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    breakpoints: {
      576: {
        slidesPerView: 2
      }
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  });
}

export function newsHeaderDisplay() {
  new Swiper('.swiper-news-header-display-container', {
    watchOverflow: true,
    simulateTouch: false,
    slidesPerView: 1,
    spaceBetween: 20,
    speed: 500,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  });
}

export function nextGenIncSwiper() {
  new Swiper('.next-gen-inc-swiper-container', {
    watchOverflow: true,
    simulateTouch: false,
    slidesPerView: 1,
    speed: 1000,
    loop: true,
    autoplay: {
      delay: 7000,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.js-swiper-next',
      prevEl: '.js-swiper-prev'
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      type: 'bullets'
    }
  });
}

export function shareSwiper() {
  let swipers = document.querySelectorAll('.js-watch-end-share-container');
  for (let i = 0; i < swipers.length; i++) {
    const node = swipers[i];

    node.classList.add('js-watch-end-share-container-' + i);

    new Swiper('.js-watch-end-share-container-' + i, {
      loop: false,
      simulateTouch: true,
      spaceBetween: 30,
      slidesPerView: 1,
      centeredSlides: false,
      navigation: {
        nextEl: '.js-swiper-next-' + i,
        prevEl: '.js-swiper-prev-' + i,
        disabledClass: 'disabled_swiper_button'
      },
      breakpoints: {
        768: {
          slidesPerView: 2
        },
        992: {
          slidesPerView: 3
        },
        1200: {
          spaceBetween: 30,
          slidesPerView: 3
        }
      }
    });
  }
}

export function ab80Swiper() {
  let swipers = document.querySelectorAll('.ab80-swiper-container');
  for (let i = 0; i < swipers.length; i++) {
    const node = swipers[i];

    node.classList.add('ab80-swiper-container-' + i);

    new Swiper('.ab80-swiper-container-' + i, {
      // watchOverflow: true,
      centeredSlides: true,
      simulateTouch: true,
      slidesPerView: 1,
      spaceBetween: 32,
      speed: 1000,
      loop: true,
      // width: 852,
      autoplay: {
        delay: 7000,
        disableOnInteraction: false
      },
      navigation: {
        nextEl: '.js-swiper-next-' + i,
        prevEl: '.js-swiper-prev-' + i,
        disabledClass: 'disabled_swiper_button'
      }
    });
  }
}

export function ecZagrebImageSwiper() {
  new Swiper('.js-ec-zagreb-image-container', {
    watchOverflow: true,
    simulateTouch: true,
    slidesPerView: 1,
    spaceBetween: 20,
    speed: 1000,
    loop: true,
    autoplay: {
      delay: 7000,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.js-swiper-next',
      prevEl: '.js-swiper-prev',
      disabledClass: 'disabled_swiper_button_ec_zagreb'
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      type: 'bullets'
    }
  });
}

export function fightHateInstagramSwiper() {
  new Swiper('.fight-hate-instagram-swiper', {
    watchOverflow: true,
    simulateTouch: false,
    speed: 1000,
    loop: true,
    spaceBetween: 20,
    allowTouchMove: true,
    slidesPerView: 'auto',
    autoplay: {
      delay: 7000,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.js-swiper-next',
      prevEl: '.js-swiper-prev'
    }
  });
}

export function relatedPrograms() {
  new Swiper('.js-swiper-related-programs', {
    watchOverflow: true,
    simulateTouch: false,
    slidesPerView: 1,
    spaceBetween: 20,
    speed: 500,
    // This is temporary commented to see if the client will like it with 4 slides per view
    // IF it doesnt also uncomment the class in issues.scss - swiper-slide.related-program-slide
    // slidesPerView: 'auto',
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.js-swiper-next',
      prevEl: '.js-swiper-prev'
    },
    breakpoints: {
      576: {
        slidesPerView: 2
      },
      992: {
        slidesPerView: 3
      },
      1200: {
        spaceBetween: 30,
        slidesPerView: 4
      }
    }
  });
}

export function swiperWithoutLoop() {
  new Swiper('.js-swiper-without-loop', {
    watchOverflow: true,
    simulateTouch: false,
    slidesPerView: 1,
    spaceBetween: 20,
    speed: 500,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.js-swiper-next',
      prevEl: '.js-swiper-prev'
    },
    breakpoints: {
      576: {
        slidesPerView: 2
      },
      992: {
        slidesPerView: 3
      },
      1200: {
        spaceBetween: 30,
        slidesPerView: 4
      }
    }
  });
}

export function swiperWithLoopShowNextImage() {
  new Swiper('.js-swiper-with-loop-next-image', {
    watchOverflow: true,
    simulateTouch: true,
    slidesPerView: 1.2,
    spaceBetween: 20,
    speed: 500,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.js-swiper-next',
      prevEl: '.js-swiper-prev'
    }
  });
}

export function swiperWithAutoSlidesPerView() {
  new Swiper('.js-swiper-with-auto-sliders', {
    watchOverflow: true,
    simulateTouch: true,
    slidesPerView: 5,
    spaceBetween: 30,
    speed: 500,
    navigation: {
      nextEl: '.js-swiper-next',
      prevEl: '.js-swiper-prev'
    },
    breakpoints: {
      576: {
        slidesPerView: 2
      },
      768: {
        slidesPerView: 3
      },
      992: {
        slidesPerView: 4
      },
      1200: {
        spaceBetween: 30,
        slidesPerView: 5
      }
    }
  });
}
